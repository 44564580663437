html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.mantine-InputWrapper-error {
  margin-top: 16px;
}
